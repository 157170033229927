/* Font */

$font-primary: "Montserrat",
sans-serif;
$font-secondary: "Quicksand",
sans-serif;

/* Colors */

$color-black: #231f20;
$color-black-2: #242021;
$color-white: #ffffff;
$color-red: #0D203B;
// Blue
$color-blue-1: #00a8ff;
$color-blue-2: #0089d0;
// Grey
$color-grey-1: #f5f5f5;
$color-grey-2: #bec0c2;
$color-grey-3: #b5bbc0;
$color-grey-4: #d6d9db;
$color-grey-5: #d0d0d0;
$color-grey-6: #aaaaaa;
$color-grey-7: #878787;

/* Media Queries */

$tablet-down: '992px';