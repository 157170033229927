.document-admin {

  h1 {
    padding-top: 35px;
  }

  .back {
    position: absolute;
    top: 30px;
    left: 120px;
    z-index: 1;

    @media (max-width: $tablet-down) {
      left: 0;
    }

    a {
      font-weight: 700;
    }
  }

  div.directory-tree-parent {
    margin-top: 50px;
    background-color: #FAFAFA;
    margin-bottom: 50px;
    padding: 20px;
    border-radius: 4px;
  }

  ul[role="tree"] {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 120%;
  }

  [role="treeitem"].doc:before {
    content: url(../img/file.svg);
  }

  [role="treeitem"].doc {
    color: $color-red;
  }

  [role="treeitem"][aria-expanded="false"] > ul {
    display: none;
  }

  [role="treeitem"][aria-expanded="true"] > ul {
    display: block;
  }

  [role="treeitem"][aria-expanded="false"] > p:before {
    content: url(../img/closed.svg);
  }

  h2.folder-name-title {
    padding-top: 50px;
  }

  a.hidden {
    display: none;
  }

  [role="treeitem"][aria-expanded="true"] > p:before {
    content: url(../img/open.svg);
  }

  [role="treeitem"],
  [role="treeitem"] p {
    margin: 0;
    padding: 0;
    border: 2px transparent solid;
    padding: 0.125em;
    cursor: pointer;
    font-size:15px;
    color:#414141;
  }

  /* disable default keyboard focus styling for treeitems
     Keyboard focus is styled with the following CSS */
  [role="treeitem"]:focus {
    outline: 0;
  }

  .clicked-folder {
    background-color: #f1f1f1;
    border-radius: 4px;
  }

  a.directory_tree_action {
    margin-right: 20px;
    float: right;
    cursor: pointer;
  }

  [role="treeitem"].focus,
  [role="treeitem"] p.focus {
    //border-color: black;
    font-weight: bold;
    //width: 9em;
  }

  [role="treeitem"].hover,
  [role="treeitem"] p:hover {
    //font-weight: bold;
    //width: 9em;
  }

}