.risk-matrices {

  width: 88%;
  margin-left: auto;
  margin-right: auto;

  div.description-pre-wrap {
    white-space: pre-wrap;
    padding: 0;
    margin: 0
  }

}