/* Font */
/* Colors */
/* Media Queries */
.document-admin {
  /* disable default keyboard focus styling for treeitems
     Keyboard focus is styled with the following CSS */ }
  .document-admin h1 {
    padding-top: 35px; }
  .document-admin .back {
    position: absolute;
    top: 30px;
    left: 120px;
    z-index: 1; }
    @media (max-width: 992px) {
      .document-admin .back {
        left: 0; } }
    .document-admin .back a {
      font-weight: 700; }
  .document-admin div.directory-tree-parent {
    margin-top: 50px;
    background-color: #FAFAFA;
    margin-bottom: 50px;
    padding: 20px;
    border-radius: 4px; }
  .document-admin ul[role="tree"] {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 120%; }
  .document-admin [role="treeitem"].doc:before {
    content: url(../img/file.svg); }
  .document-admin [role="treeitem"].doc {
    color: #0D203B; }
  .document-admin [role="treeitem"][aria-expanded="false"] > ul {
    display: none; }
  .document-admin [role="treeitem"][aria-expanded="true"] > ul {
    display: block; }
  .document-admin [role="treeitem"][aria-expanded="false"] > p:before {
    content: url(../img/closed.svg); }
  .document-admin h2.folder-name-title {
    padding-top: 50px; }
  .document-admin a.hidden {
    display: none; }
  .document-admin [role="treeitem"][aria-expanded="true"] > p:before {
    content: url(../img/open.svg); }
  .document-admin [role="treeitem"],
  .document-admin [role="treeitem"] p {
    margin: 0;
    padding: 0;
    border: 2px transparent solid;
    padding: 0.125em;
    cursor: pointer;
    font-size: 15px;
    color: #414141; }
  .document-admin [role="treeitem"]:focus {
    outline: 0; }
  .document-admin .clicked-folder {
    background-color: #f1f1f1;
    border-radius: 4px; }
  .document-admin a.directory_tree_action {
    margin-right: 20px;
    float: right;
    cursor: pointer; }
  .document-admin [role="treeitem"].focus,
  .document-admin [role="treeitem"] p.focus {
    font-weight: bold; }

.risk-matrices {
  width: 88%;
  margin-left: auto;
  margin-right: auto; }
  .risk-matrices div.description-pre-wrap {
    white-space: pre-wrap;
    padding: 0;
    margin: 0; }

.financial-service-providers {
  width: 88%;
  margin-left: auto;
  margin-right: auto; }
  .financial-service-providers .btn-floating {
    right: 20px; }

td.notes {
  font-size: 11px !important; }

table.report-parent {
  margin-bottom: 20px !important; }

.high-risk {
  color: #b70e28;
  font-weight: bold; }

.med-risk {
  color: #ffa31f;
  font-weight: bold; }

.low-risk {
  color: #53b660;
  font-weight: bold; }

/* DEFAULTS */
/*
[ng\:cloak], [ng-cloak], .ng-cloak {
    display: none !important;
}
*/
html {
  line-height: 1.5;
  color: #231f20;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400; }

body {
  overflow-y: scroll; }

a {
  color: #0D203B; }
  a:hover, a:active, a:focus {
    color: #0089d0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  text-align: center;
  color: #242021; }

h1 {
  margin: 2.8rem 0 1rem 0;
  font-size: 1.71429rem;
  text-transform: uppercase;
  color: #0D203B; }
  .authentication h1 {
    margin: 2.8rem 0 2.1rem 0; }
  .fsp.fsp-details h1 {
    margin: 1.8rem 0 2.7rem 0; }
  .report-preview h1 {
    margin: 0 0 0 0; }

h2 {
  font-size: 1.28571rem;
  text-transform: uppercase; }
  .fsp.fsp-details h2 {
    margin: 0; }

h3 {
  font-size: 1.14286rem; }
  .menu h3 {
    margin: 1rem 0 0.8rem 0;
    text-align: left;
    text-transform: uppercase;
    color: #0D203B; }
  .report-preview h3 {
    margin: 3.35rem 0 0.3rem 0;
    text-transform: uppercase; }

h4 {
  margin: 1.05rem 0 2.3rem 0;
  line-height: 125%;
  font-size: 1rem; }
  .authentication h4 {
    margin: 1.1rem 0 3rem 0;
    line-height: 110%; }
  .report-preview h4 {
    margin: 0.5rem 0 3.15rem 0;
    text-transform: uppercase; }

h5 {
  font-size: 0.85714rem; }

input,
label,
button,
.btn {
  font-size: 1rem; }

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
nput[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
  box-sizing: border-box;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  padding: 6px 9px;
  height: 2.8rem; }
  input:not([type]):-webkit-autofill,
  input[type=text]:not(.browser-default):-webkit-autofill,
  input[type=password]:not(.browser-default):-webkit-autofill,
  input[type=email]:not(.browser-default):-webkit-autofill,
  input[type=url]:not(.browser-default):-webkit-autofill,
  nput[type=time]:not(.browser-default):-webkit-autofill,
  input[type=date]:not(.browser-default):-webkit-autofill,
  input[type=datetime]:not(.browser-default):-webkit-autofill,
  input[type=datetime-local]:not(.browser-default):-webkit-autofill,
  input[type=tel]:not(.browser-default):-webkit-autofill,
  input[type=number]:not(.browser-default):-webkit-autofill,
  input[type=search]:not(.browser-default):-webkit-autofill,
  textarea.materialize-textarea:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important; }
  input:not([type]):focus,
  input[type=text]:not(.browser-default):focus,
  input[type=password]:not(.browser-default):focus,
  input[type=email]:not(.browser-default):focus,
  input[type=url]:not(.browser-default):focus,
  nput[type=time]:not(.browser-default):focus,
  input[type=date]:not(.browser-default):focus,
  input[type=datetime]:not(.browser-default):focus,
  input[type=datetime-local]:not(.browser-default):focus,
  input[type=tel]:not(.browser-default):focus,
  input[type=number]:not(.browser-default):focus,
  input[type=search]:not(.browser-default):focus,
  textarea.materialize-textarea:focus {
    border-bottom: 1px solid #0D203B !important;
    box-shadow: 0 1px 0 0 #0D203B !important;
    -webkit-box-shadow: 0 1px 0 0 #0D203B !important; }
  .authentication input:not([type]), .authentication
  input[type=text]:not(.browser-default), .authentication
  input[type=password]:not(.browser-default), .authentication
  input[type=email]:not(.browser-default), .authentication
  input[type=url]:not(.browser-default), .authentication
  nput[type=time]:not(.browser-default), .authentication
  input[type=date]:not(.browser-default), .authentication
  input[type=datetime]:not(.browser-default), .authentication
  input[type=datetime-local]:not(.browser-default), .authentication
  input[type=tel]:not(.browser-default), .authentication
  input[type=number]:not(.browser-default), .authentication
  input[type=search]:not(.browser-default), .authentication
  textarea.materialize-textarea {
    border: 0;
    border-bottom: 1px solid #aaaaaa;
    border-radius: 0;
    padding: 0;
    height: 1.8rem; }
  input:not([type]).width-number,
  input[type=text]:not(.browser-default).width-number,
  input[type=password]:not(.browser-default).width-number,
  input[type=email]:not(.browser-default).width-number,
  input[type=url]:not(.browser-default).width-number,
  nput[type=time]:not(.browser-default).width-number,
  input[type=date]:not(.browser-default).width-number,
  input[type=datetime]:not(.browser-default).width-number,
  input[type=datetime-local]:not(.browser-default).width-number,
  input[type=tel]:not(.browser-default).width-number,
  input[type=number]:not(.browser-default).width-number,
  input[type=search]:not(.browser-default).width-number,
  textarea.materialize-textarea.width-number {
    width: 100px; }
  .search input:not([type]), .search
  input[type=text]:not(.browser-default), .search
  input[type=password]:not(.browser-default), .search
  input[type=email]:not(.browser-default), .search
  input[type=url]:not(.browser-default), .search
  nput[type=time]:not(.browser-default), .search
  input[type=date]:not(.browser-default), .search
  input[type=datetime]:not(.browser-default), .search
  input[type=datetime-local]:not(.browser-default), .search
  input[type=tel]:not(.browser-default), .search
  input[type=number]:not(.browser-default), .search
  input[type=search]:not(.browser-default), .search
  textarea.materialize-textarea {
    border-radius: 32px;
    padding: 6px 14px;
    height: 2.3rem;
    width: 220px; }
    .search input:not([type]):focus, .search
    input[type=text]:not(.browser-default):focus, .search
    input[type=password]:not(.browser-default):focus, .search
    input[type=email]:not(.browser-default):focus, .search
    input[type=url]:not(.browser-default):focus, .search
    nput[type=time]:not(.browser-default):focus, .search
    input[type=date]:not(.browser-default):focus, .search
    input[type=datetime]:not(.browser-default):focus, .search
    input[type=datetime-local]:not(.browser-default):focus, .search
    input[type=tel]:not(.browser-default):focus, .search
    input[type=number]:not(.browser-default):focus, .search
    input[type=search]:not(.browser-default):focus, .search
    textarea.materialize-textarea:focus {
      border-bottom: 1px solid #d0d0d0 !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important; }
    .search input:not([type]) + i, .search
    input[type=text]:not(.browser-default) + i, .search
    input[type=password]:not(.browser-default) + i, .search
    input[type=email]:not(.browser-default) + i, .search
    input[type=url]:not(.browser-default) + i, .search
    nput[type=time]:not(.browser-default) + i, .search
    input[type=date]:not(.browser-default) + i, .search
    input[type=datetime]:not(.browser-default) + i, .search
    input[type=datetime-local]:not(.browser-default) + i, .search
    input[type=tel]:not(.browser-default) + i, .search
    input[type=number]:not(.browser-default) + i, .search
    input[type=search]:not(.browser-default) + i, .search
    textarea.materialize-textarea + i {
      position: absolute;
      right: 10px;
      margin-top: 4px;
      color: #878787; }

.recycle-bin-header {
  text-align: left;
  line-height: 100%;
  margin: 60px 0 10px 0;
  font-size: 1.5rem; }

.calendar-month {
  background-color: #0D47A1 !important; }

textarea.materialize-textarea {
  padding: 10px 9px;
  height: 200px; }

pre {
  font-family: "Montserrat", sans-serif; }

.select-wrapper .caret {
  display: none !important; }

.select-dropdown {
  display: none !important; }

select {
  display: block;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  border: 1px solid #d0d0d0;
  border-radius: 4px; }

input {
  padding: 0px;
  margin: 0px; }

label {
  display: block;
  margin-bottom: 4px;
  color: #231f20; }
  label.border-bottom {
    border-bottom: 1px solid #d0d0d0;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem; }

.color-red {
  color: #0D203B;
  font-weight: bold;
  font-size: 1.2rem; }

.ics-small {
  font-size: 10px; }

.bg-red {
  background: #0D203B !important; }

.bg-grey-1 {
  background: #f5f5f5; }

.hidden {
  display: none; }

.bold {
  font-weight: 700; }

.center, .center-align {
  text-align: center !important; }

.clear {
  clear: both; }

/* SITEWIDE */
.row {
  margin-bottom: 17px; }

p.notice {
  font-size: 12px;
  color: #555555;
  font-style: italic;
  margin: 0;
  padding: 0; }

div.fsp-report-logo {
  border-radius: 2px;
  border: 1px solid #EBEBEB;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover !important;
  width: 160px;
  height: 160px;
  margin: 40px auto; }

img.fsp-report-logo {
  border-radius: 2px;
  border: 1px solid #bcbcbc;
  max-width: 180px;
  max-height: 180px;
  margin: 40px auto; }

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4; }
  header nav {
    position: relative;
    box-shadow: none;
    background: #0D203B;
    height: 66px;
    line-height: 66px;
    opacity: 0;
    transition: all .35s; }
    header nav.signed-in {
      display: block;
      opacity: 1; }
      header nav.signed-in .brand-logo {
        position: relative;
        display: inline-block;
        margin: 13px 0 0 20px;
        background-image: url(/static/img/logo-ics-icon-new.png);
        background-repeat: no-repeat;
        width: 67px;
        height: auto;
        text-indent: -9999px; }
        @media (max-width: 992px) {
          header nav.signed-in .brand-logo {
            left: auto;
            transform: none; } }
    header nav.signed-out {
      position: fixed;
      display: block;
      background-color: #f5f5f5;
      background-image: linear-gradient(to bottom, #0D203B, #0D203B 33.33333%);
      background-size: 100% 33.3333333333%;
      background-repeat: no-repeat;
      height: 110vh;
      opacity: 1; }
    header nav a {
      font-weight: 700; }
      header nav a:hover, header nav a:active, header nav a:focus {
        color: #ffffff; }
      header nav a i {
        display: inline-block !important;
        margin-left: 4px;
        height: auto;
        line-height: normal; }

main {
  position: relative;
  margin-top: 66px;
  min-height: calc(100vh - 83px);
  opacity: 1;
  transition: all .35s; }
  main.loading {
    opacity: 0;
    pointer-events: none; }
  main.valign-wrapper {
    margin-top: 0;
    min-height: 100vh;
    opacity: 1;
    z-index: 4; }
    main.valign-wrapper > .container {
      box-sizing: border-box;
      padding-left: 0; }
  main .brand-logo {
    display: block;
    margin: 0 auto;
    max-width: 100%; }
  main > .container {
    box-sizing: content-box;
    max-width: 1600px;
    /*min-height: 100vh;*/ }
    @media (max-width: 992px) {
      main > .container {
        padding-left: 0; } }
    main > .container.fsp {
      width: 90%; }
      main > .container.fsp img.fsp-details-logo {
        display: inline-block;
        border-radius: 2px;
        border: 1px solid #bcbcbc;
        max-width: 70px;
        max-height: 70px;
        margin: auto; }
      main > .container.fsp .fsp-logo {
        display: inline-block;
        margin-top: -1px;
        margin-right: 12px;
        vertical-align: middle; }
  main .container-side-nav {
    position: fixed;
    top: 0;
    display: none;
    float: left;
    /*position: absolute;*/
    /*margin-top: -39px;*/
    margin-top: 66px;
    background: #242021;
    width: 60px;
    height: 100%;
    z-index: 1000;
    z-index: 3; }
    main .container-side-nav > a {
      margin-top: 15px;
      display: block;
      text-align: center;
      color: #ffffff; }
    main .container-side-nav .side-nav {
      top: auto;
      background: #242021;
      padding-bottom: 80px;
      height: 100%; }
      main .container-side-nav .side-nav li {
        line-height: 60px; }
        main .container-side-nav .side-nav li a {
          display: flex;
          align-items: center;
          padding: 0 20px;
          line-height: inherit;
          width: 100%;
          height: 60px;
          line-height: 13px;
          font-size: 0.92857rem;
          font-weight: 300;
          color: #ffffff;
          overflow: hidden; }
          main .container-side-nav .side-nav li a:not(.close-side-nav):hover, main .container-side-nav .side-nav li a:not(.close-side-nav):active, main .container-side-nav .side-nav li a:not(.close-side-nav):focus {
            background: #0D203B; }
          main .container-side-nav .side-nav li a.close-side-nav i {
            position: relative;
            top: -4px;
            left: -3px;
            color: #ffffff; }
  main .controls {
    position: absolute;
    top: 19px;
    left: 82px;
    z-index: 1; }
    @media (max-width: 992px) {
      main .controls {
        left: 0; } }
    main .controls a {
      font-weight: 700; }
  main .menu {
    margin: 0 0 30px 0 !important;
    background: #ffffff !important;
    height: auto !important; }
    main .menu .section {
      position: relative;
      margin: 0 -17px;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 14px;
      padding-right: 14px; }
      main .menu .section:after {
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: 1px solid #d0d0d0;
        content: "";
        width: 100%; }
      main .menu .section.header {
        background-color: #d6d9db; }
        main .menu .section.header li {
          color: #231f20; }
          main .menu .section.header li span {
            color: #0D203B; }
      main .menu .section:last-child:after {
        display: none; }
      main .menu .section ul li {
        line-height: 1.75;
        font-size: 0.85714rem;
        font-weight: 400; }
        main .menu .section ul li a {
          color: #242021; }
          main .menu .section ul li a:hover, main .menu .section ul li a:active, main .menu .section ul li a:focus {
            color: #0D203B; }
      main .menu .section:last-child ul {
        margin-bottom: 0.75rem; }
    main .menu + div > .row {
      margin-bottom: 12px; }

.drag-target {
  width: 60px;
  z-index: 1; }
  @media (max-width: 992px) {
    .drag-target {
      display: none; } }

#sidenav-overlay {
  display: none !important; }

/* ELEMENTS */
.material-icons {
  vertical-align: middle; }

.loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(190, 192, 194, 0.8);
  width: 100%;
  height: 100%;
  z-index: 3; }

.preloader-wrapper.small {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 24px);
  width: 24px;
  height: 24px;
  vertical-align: middle; }

.preloader-wrapper.main {
  position: fixed;
  top: 50%;
  left: calc(50% - 25px);
  z-index: 2; }

.preloader-wrapper .spinner-layer {
  border-color: #0089d0; }

.tabs {
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 14px;
  height: 38px;
  overflow: visible; }
  .tabs .tab {
    padding: 0 !important;
    height: 38px;
    line-height: 38px; }
    .tabs .tab a {
      padding: 0;
      font-family: "Quicksand", sans-serif;
      font-size: 1.28571rem;
      font-weight: 400;
      color: #878787;
      text-align: left; }
      .tabs .tab a.active {
        color: #242021; }
  .tabs .indicator {
    bottom: -1px;
    background-color: #0D203B;
    height: 4px; }

.dashboard .row,
.fsp .row {
  margin-left: -17px;
  margin-right: -17px; }

.admin:not(.fsp-details) .row {
  margin-left: -17px;
  margin-right: -17px; }

.dashboard .row .col,
.fsp .row .col {
  padding: 0 17px; }

.admin:not(.fsp-details) .row .col {
  padding: 0 17px;
  /*+ .col {
          padding: 0;
      }*/ }
  .admin:not(.fsp-details) .row .col .row {
    margin-left: 0;
    margin-right: 0; }
    .admin:not(.fsp-details) .row .col .row .col {
      padding: 0; }

.row .card {
  border-radius: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -2px rgba(0, 0, 0, 0.1);
  background: #f5f5f5; }
  .row .card.authentication {
    /*position: absolute;
      top: 50%;
      transform: translateY(-50%);*/
    margin: 0 auto 0 auto;
    box-shadow: 0 2px 2px 0 rgba(36, 37, 37, 0.1), 0 1px 5px 0 rgba(36, 37, 37, 0.1), 0 3px 1px -2px rgba(36, 37, 37, 0.1);
    background: #ffffff;
    max-width: 600px; }
    .row .card.authentication .card-content {
      padding: 42px 60px 27px 60px;
      border-radius: 0; }
  .dashboard .row .card {
    margin: 17px 0;
    height: 200px; }
    .dashboard .row .card .card-content {
      padding: 0;
      height: 100%; }
      .dashboard .row .card .card-content a {
        display: block;
        height: 100%;
        padding: 20px;
        color: #242021;
        text-transform: uppercase;
        text-align: center; }
        .dashboard .row .card .card-content a:hover {
          display: flex;
          align-items: center;
          background: #0D203B;
          color: #ffffff; }
          .dashboard .row .card .card-content a:hover .icon-container {
            display: none; }
          .dashboard .row .card .card-content a:hover span {
            position: static; }
        .dashboard .row .card .card-content a span {
          position: absolute;
          left: 0;
          bottom: 15px;
          text-align: center;
          width: 100%; }
    .dashboard .row .card .icon-container {
      display: block;
      margin: 0 auto 41px auto;
      border-radius: 50%;
      background: #d6d9db;
      width: 106px;
      height: 106px;
      line-height: 109px;
      font-size: 60px;
      color: #ffffff;
      /*&.icon-fsp_clients {
            background-image: url('/static/img/icon-fsp_clients.png');
        }

        &.icon-generate_reports {
            background-image: url('/static/img/icon-generate_reports.png');
        }

        &.icon-list_management {
            background-image: url('/static/img/icon-list_management.png');
        }

        &.icon-document_templates {
            background-image: url('/static/img/icon-document_templates.png');
        }

        &.icon-email_templates {
            background-image: url('/static/img/icon-email_templates.png');
        }

        &.icon-fsb_email_templates {
            background-image: url('/static/img/icon-fsb_email_templates.png');
        }

        &.icon-followup_email_templates {
            background-image: url('/static/img/icon-followup_email_templates.png');
        }*/ }

table:not(.picker__table) {
  table-layout: fixed;
  overflow-x: scroll;
  margin-bottom: 30px; }
  table:not(.picker__table) caption {
    border-radius: 0;
    padding: 20px 20px 18px 20px !important;
    line-height: 1.1;
    font-family: "Quicksand", sans-serif;
    font-weight: 400;
    font-size: 0.92857rem;
    text-align: left;
    vertical-align: middle;
    text-transform: uppercase; }
    table:not(.picker__table) caption.bg-red {
      color: #ffffff; }
  table:not(.picker__table) thead {
    border-bottom: 0; }
    table:not(.picker__table) thead tr {
      border: 0;
      background: #d6d9db; }
      table:not(.picker__table) thead tr th {
        float: none !important;
        border-radius: 0;
        padding: 16px 18px 14px 16px !important;
        line-height: 1.1;
        font-family: "Quicksand", sans-serif;
        font-weight: 400;
        font-size: 0.92857rem;
        vertical-align: middle;
        text-transform: uppercase;
        /*white-space: nowrap;*/ }
        table:not(.picker__table) thead tr th b {
          font-weight: 500; }
      table:not(.picker__table) thead tr.spacer {
        background: transparent; }
        table:not(.picker__table) thead tr.spacer th {
          padding: 0 !important;
          height: 18px;
          line-height: 18px;
          font-size: 0.92857rem; }
  table:not(.picker__table) tbody {
    background: #ffffff;
    padding: 3px 0 3px; }
    table:not(.picker__table) tbody tr.pointer {
      cursor: pointer; }
    table:not(.picker__table) tbody tr td {
      float: none !important;
      border-radius: 0;
      padding: 20px 20px 18px 20px !important;
      line-height: 1.1;
      font-size: 0.92857rem;
      word-break: normal; }
      @media (max-width: 992px) {
        table:not(.picker__table) tbody tr td {
          word-break: normal; } }
      table:not(.picker__table) tbody tr td b {
        font-weight: 500; }
  .dashboard:not(.bg-grey-1) table:not(.picker__table):not(.vertical) tbody {
    background: #f5f5f5; }
  table:not(.picker__table).bordered tbody tr {
    border-bottom: 1px solid #d0d0d0; }
  table:not(.picker__table).striped > tbody > tr:nth-child(odd) {
    background-color: #ffffff; }
  table:not(.picker__table).striped > tbody > tr:nth-child(even) {
    background-color: #f5f5f5; }
  table:not(.picker__table).border {
    border: 1px solid #d6d9db; }
  .fsp.fsp-details table:not(.picker__table) tr:not(.spacer) td {
    font-size: 0.85714rem; }
  .fsp.fsp-details table:not(.picker__table) tr:not(.spacer) th {
    padding: 12px 20px 12px 20px !important;
    font-size: 0.85714rem;
    /*white-space: normal;*/ }
  .report-generate table:not(.picker__table) th {
    padding: 13px 20px 13px 20px !important;
    font-size: 0.85714rem; }
  .report-generate table:not(.picker__table) td {
    padding: 18px 20px 19px 20px !important;
    font-size: 0.85714rem; }
  .report-generate table:not(.picker__table) tr.sub-header {
    background-color: #EBEBEB;
    font-weight: bold; }
  .report-risk table:not(.picker__table) th.main {
    padding: 5px !important;
    font-size: 0.85714rem;
    border: 1px solid #DDDDDD !important;
    background-color: #0D203B !important;
    color: #FAFAFA; }
  .report-risk table:not(.picker__table) td {
    vertical-align: middle;
    padding: 8px 5px !important;
    font-size: 0.85714rem;
    border: 1px solid #DDDDDD !important; }
    .report-risk table:not(.picker__table) td input[type=text] {
      margin: 0; }
    .report-risk table:not(.picker__table) td .high-risk {
      font-weight: bold !important;
      color: #0D203B !important; }
    .report-risk table:not(.picker__table) td .med-risk {
      font-weight: bold !important;
      color: #ffa31f !important; }
    .report-risk table:not(.picker__table) td .low-risk {
      font-weight: bold !important;
      color: #53b660 !important; }
    .report-risk table:not(.picker__table) td .prob-impact {
      text-transform: uppercase;
      text-align: center; }
  .report-risk table:not(.picker__table) tr.sub-header {
    background-color: #EBEBEB;
    font-weight: bold; }
  .report-preview table:not(.picker__table) caption {
    padding: 11px 9px 10px 9px !important;
    font-weight: 500; }
  .report-preview table:not(.picker__table) th {
    padding: 11px 9px 10px 9px !important;
    text-transform: none;
    font-size: 0.78571rem;
    font-weight: 500; }
  .report-preview table:not(.picker__table) td {
    padding: 11px 9px 10px 9px !important;
    font-size: 0.78571rem;
    vertical-align: top; }
  table:not(.picker__table).vertical > tbody > tr {
    background: #d6d9db; }
    table:not(.picker__table).vertical > tbody > tr > td {
      display: inline-block;
      background: #ffffff; }
      table:not(.picker__table).vertical > tbody > tr > td input,
      table:not(.picker__table).vertical > tbody > tr > td textarea,
      table:not(.picker__table).vertical > tbody > tr > td select,
      table:not(.picker__table).vertical > tbody > tr > td .file-field .btn,
      table:not(.picker__table).vertical > tbody > tr > td .file-field .btn:hover {
        margin-bottom: 0; }
    table:not(.picker__table).vertical > tbody > tr > th {
      float: none !important;
      display: inline-block;
      border-radius: 0;
      padding: 20px 20px 18px 20px !important;
      line-height: 1.1;
      font-size: 0.92857rem;
      font-family: "Quicksand", sans-serif;
      font-weight: 400;
      vertical-align: middle;
      text-transform: uppercase; }
      table:not(.picker__table).vertical > tbody > tr > th b {
        font-weight: 500; }

div.report-footer {
  padding-top: 40px;
  font-size: 12px;
  color: #434343; }
  div.report-footer p.review {
    padding: 30px 5px; }
    div.report-footer p.review span.review-description {
      font-style: italic;
      font-size: 12px; }
  div.report-footer p.signature {
    padding: 10px 5px; }
  div.report-footer p.signature.first {
    padding-top: 40px; }
  div.report-footer li {
    padding: 5px; }

.btn {
  border-radius: 4px;
  background-color: #0D203B;
  padding: 0 2.25rem;
  letter-spacing: -.2px;
  color: #ffffff;
  transition: all .25s; }
  .btn.width-fixed {
    padding: 0;
    width: 105px; }
  .btn:hover, .btn:active, .btn:focus {
    background-color: #0089d0;
    color: #ffffff; }
  .btn.bg-grey-5 {
    background-color: #d0d0d0; }
    .btn.bg-grey-5:hover, .btn.bg-grey-5:active, .btn.bg-grey-5:focus {
      background-color: #0089d0; }
  .btn.btn-small {
    padding: 0 0.75rem; }
  .btn + .btn {
    margin-left: 10px; }

tr.not-applicable {
  background-color: #EBEBEB;
  color: #444444; }
  tr.not-applicable td {
    background-color: #EBEBEB;
    color: #444444; }

.btn-floating {
  position: fixed;
  top: 100px;
  margin-left: -25px;
  background-color: #0D203B;
  width: 70px;
  height: 70px; }
  .btn-floating:hover, .btn-floating:active, .btn-floating:focus {
    background-color: #0089d0;
    color: #ffffff; }
  .fsp .btn-floating {
    right: 37px; }
  .btn-floating i {
    line-height: 70px; }

.file-field .btn, .file-field .btn:hover {
  display: block;
  float: none;
  margin: 0 auto 15px auto;
  border: 1px solid #d0d0d0;
  background: none;
  box-shadow: none;
  padding: 38px 10px 0 7px;
  width: 100%;
  height: 160px;
  max-width: 500px;
  text-align: center; }
  .file-field .btn span.dropdown-hint, .file-field .btn:hover span.dropdown-hint {
    display: block;
    margin-top: 5%;
    font-size: 0.85714rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: none;
    color: #242021; }

.file-field .file-path-wrapper .file-path {
  position: absolute;
  margin: 0 0 -1px 0;
  padding: 0;
  width: 1px;
  height: 1px;
  visibility: hidden; }

.file-field .logo {
  display: block;
  margin: 0 auto 15px auto;
  max-width: 300px; }

.modal {
  /*top: 25% !important;*/ }
  .modal h2 {
    text-align: left;
    color: #0D203B;
    text-transform: uppercase; }
  .modal p:last-child {
    margin-bottom: 0; }
  .modal .modal-footer {
    border-top: 1px solid #d0d0d0;
    padding: 0 20px;
    line-height: 56px; }
    .modal .modal-footer a {
      font-weight: 700; }
      .modal .modal-footer a:last-child {
        margin-left: 22px; }
      .modal .modal-footer a i {
        font-weight: 700; }

.modal-overlay {
  background: #bec0c2; }

.list-dropdown {
  position: relative;
  display: inline-block; }

.list-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 320px;
  max-width: 500px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 8px 8px 0px 8px;
  z-index: 9999; }

.list-dropdown:hover .list-dropdown-content {
  display: block; }

.picker__date-display {
  background-color: #0D203B; }

.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  background-color: #0D203B; }

.picker__footer button {
  color: #0D203B; }
  .picker__footer button:hover, .picker__footer button:active, .picker__footer button:focus {
    background-color: transparent;
    color: #0089d0; }

.checkbox {
  padding-left: 0 !important;
  height: 15px !important;
  line-height: 15px !important; }

[type="checkbox"].filled-in + label {
  padding-left: 0 !important;
  height: 15px !important;
  line-height: 15px !important; }

[type="checkbox"].filled-in:not(:checked) + label:after {
  border: 2px solid #d0d0d0; }

[type="checkbox"].filled-in:checked + label:after {
  border: 2px solid #0D203B;
  background-color: #0D203B; }

[type="checkbox"]:not(.filled-in):not(:checked):disabled + label:before {
  background-color: #d0d0d0; }

[type="checkbox"]:not(.filled-in):not(:checked) + label:before {
  border: 2px solid #d0d0d0; }

[type="checkbox"]:not(.filled-in):not(:checked) + label:after {
  border: 2px solid #d0d0d0; }

[type="checkbox"]:not(.filled-in):checked + label:before {
  top: 0;
  left: 0;
  border-right: 2px solid #0D203B;
  border-bottom: 2px solid #0D203B;
  width: 8px;
  height: 13px; }

div > [type="checkbox"].filled-in + label,
table.vertical [type="checkbox"].filled-in + label {
  padding-left: 35px !important;
  margin-bottom: 20px;
  color: #231f20; }

div > [type="checkbox"].filled-in:not(:checked) + label:after,
table.vertical [type="checkbox"].filled-in:not(:checked) + label:after {
  top: -3px; }

div > [type="checkbox"].filled-in:checked + label:before,
table.vertical [type="checkbox"].filled-in:checked + label:before {
  top: -3px; }

div > [type="checkbox"].filled-in:checked + label:after,
table.vertical [type="checkbox"].filled-in:checked + label:after {
  top: -3px; }

table.vertical [type="checkbox"].filled-in + label {
  margin: 0; }
  table.vertical [type="checkbox"].filled-in + label + div {
    margin-top: 20px; }

/* CUSTOM */
.search {
  position: relative; }

form.col {
  float: none !important;
  margin: 0 auto; }

.fsp > .row > .col form.l8 {
  width: 51.65%; }

form p.link {
  margin-top: -11px !important;
  margin-bottom: 7px !important;
  text-align: inherit; }

form textarea + p {
  margin-top: 0; }

div:not(.buttons).border-bottom:not(:last-child) {
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  overflow: hidden; }

.buttons.border-top {
  border-top: 1px solid #d0d0d0;
  padding-top: 2rem;
  width: 100%;
  text-align: right; }
