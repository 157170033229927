.financial-service-providers {

  width: 88%;
  margin-left: auto;
  margin-right: auto;

  .btn-floating {
    right: 20px;
  }

}

td.notes {
  font-size: 11px !important;
}