table.report-parent {
  margin-bottom: 20px !important;
}

.high-risk{
  color: #b70e28;
  font-weight: bold;
}
.med-risk{
  color: #ffa31f;
  font-weight: bold;
}
.low-risk{
  color: #53b660;
  font-weight: bold;
}
