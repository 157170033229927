@import "variables";
@import "mixins";
@import "document_tree";
@import "risk_matrices";
@import "financial_service_providers";
@import "report";

/* DEFAULTS */

/*
[ng\:cloak], [ng-cloak], .ng-cloak {
    display: none !important;
}
*/

html {
  line-height: 1.5;
  color: $color-black;
  font-size: 14px;
  font-family: $font-primary;
  font-weight: 400;
}

body {
  overflow-y: scroll;
}

a {
  color: $color-red;

  &:hover,
  &:active,
  &:focus {
    color: $color-blue-2;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-secondary;
  font-weight: 400;
  text-align: center;
  color: $color-black-2;
}

h1 {
  margin: 2.8rem 0 1rem 0;
  font-size: rem-calc(24);
  text-transform: uppercase;
  color: $color-red;

  .authentication & {
    margin: 2.8rem 0 2.1rem 0;
  }

  .fsp.fsp-details & {
    margin: 1.8rem 0 2.7rem 0;
  }

  .report-preview & {
    margin: 0 0 0 0;
  }
}

h2 {
  font-size: rem-calc(18);
  text-transform: uppercase;

  .fsp.fsp-details & {
    margin: 0;
  }
}

h3 {
  font-size: rem-calc(16);

  .menu & {
    margin: 1rem 0 0.8rem 0;
    text-align: left;
    text-transform: uppercase;
    color: $color-red;
  }

  .report-preview & {
    margin: 3.35rem 0 0.3rem 0;
    text-transform: uppercase;
  }
}

h4 {
  margin: 1.05rem 0 2.3rem 0;
  line-height: 125%;
  font-size: rem-calc(14);

  .authentication & {
    margin: 1.1rem 0 3rem 0;
    line-height: 110%;
  }

  .report-preview & {
    margin: 0.5rem 0 3.15rem 0;
    text-transform: uppercase;
  }
}

h5 {
  font-size: rem-calc(12);
}

input,
label,
button,
.btn {
  font-size: rem-calc(14);
}

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
nput[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
  box-sizing: border-box;
  border: 1px solid $color-grey-5;
  border-radius: 4px;
  padding: 6px 9px;
  height: 2.8rem;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }

  &:focus {
    border-bottom: 1px solid $color-red !important;
    box-shadow: 0 1px 0 0 $color-red !important;
    -webkit-box-shadow: 0 1px 0 0 $color-red !important;
  }

  .authentication & {
    border: 0;
    border-bottom: 1px solid $color-grey-6;
    border-radius: 0;
    padding: 0;
    height: 1.8rem;
  }

  &.width-number {
    width: 100px;
  }

  .search & {
    border-radius: 32px;
    padding: 6px 14px;
    height: 2.3rem;
    width: 220px;

    &:focus {
      border-bottom: 1px solid $color-grey-5 !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }

    + i {
      position: absolute;
      right: 10px;
      margin-top: 4px;
      color: $color-grey-7;
    }
  }
}

.recycle-bin-header {
  text-align: left;
  line-height: 100%;
  margin: 60px 0 10px 0;
  font-size: 1.5rem;
}

.calendar-month {
  background-color: #0D47A1 !important;
}

textarea.materialize-textarea {
  padding: 10px 9px;
  height: 200px;
}

pre {
  font-family: $font-primary;
}

select {
  margin: 0 0 20px 0;
  box-sizing: border-box;
  border: 1px solid $color-grey-5;
  border-radius: 4px;
  padding: 6px 9px;
  height: 2.8rem;

  &:focus {
    outline: 1px solid $color-grey-1;
  }
}

label {
  display: block;
  margin-bottom: 4px;
  color: $color-black;

  &.border-bottom {
    border-bottom: 1px solid $color-grey-5;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
  }
}

.color-red {
  color: $color-red;
  font-weight: bold;
  font-size:1.2rem;
}

.ics-small {
  font-size: 10px;
}

.bg-red {
  background: $color-red !important;
}

.bg-grey-1 {
  background: $color-grey-1;
}

.hidden {
  display: none;
}


.bold {
  font-weight: 700;
}

.center, .center-align {
  text-align: center !important;
}

.clear {
  clear: both;
}

/* SITEWIDE */

.row {
  margin-bottom: 17px;
}

p.notice {
  font-size: 12px;
  color: #555555;
  font-style: italic;
  margin: 0;
  padding: 0;
}

div.fsp-report-logo {

  border-radius: 2px;
  border: 1px solid #EBEBEB;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover !important;
  width: 160px;
  height: 160px;
  margin: 40px auto;

}

img.fsp-report-logo {

  border-radius: 2px;
  border: 1px solid #bcbcbc;
  max-width: 180px;
  max-height: 180px;
  margin: 40px auto;

}

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;

  nav {
    position: relative;
    box-shadow: none;
    background: $color-red;
    height: 66px;
    line-height: 66px;
    opacity: 0;
    transition: all .35s;

    &.signed-in {
      display: block;
      opacity: 1;

      .brand-logo {
        position: relative;
        display: inline-block;
        margin: 13px 0 0 20px;
        background-image: url(/static/img/logo-ics-icon-new.png);
        background-repeat: no-repeat;
        width: 67px;
        height: auto;
        text-indent: -9999px;

        @media (max-width: $tablet-down) {
          left: auto;
          transform: none;
        }

      }

    }

    &.signed-out {
      position: fixed;
      display: block;
      background-color: $color-grey-1;
      background-image: linear-gradient(to bottom, $color-red, $color-red 33.3333333333%);
      background-size: 100% 33.3333333333%;
      background-repeat: no-repeat;
      height: 110vh;
      opacity: 1;
    }

    a {
      font-weight: 700;

      &:hover,
      &:active,
      &:focus {
        color: $color-white;
      }

      i {
        display: inline-block !important;
        margin-left: 4px;
        height: auto;
        line-height: normal;
      }
    }
  }

}

main {
  position: relative;
  margin-top: 66px;
  min-height: calc(100vh - 83px);
  opacity: 1;
  transition: all .35s;

  &.loading {
    opacity: 0;
    pointer-events: none;
  }

  &.valign-wrapper {
    margin-top: 0;
    min-height: 100vh;
    opacity: 1;
    z-index: 4;

    > .container {
      box-sizing: border-box;
      padding-left: 0;
    }
  }

  .brand-logo {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  > .container {
    box-sizing: content-box;
    // padding-left: 60px;
    max-width: 1600px;

    @media (max-width: $tablet-down) {
      padding-left: 0;
    }

    &.fsp {
      width: 90%;

      img.fsp-details-logo {
        display: inline-block;
        border-radius: 2px;
        border: 1px solid #bcbcbc;
        max-width: 70px;
        max-height: 70px;
        margin: auto;

      }

      .fsp-logo {
        display: inline-block;
        margin-top: -1px;
        margin-right: 12px;
        vertical-align: middle;
      }
    }

    /*min-height: 100vh;*/
  }

  .container-side-nav {
    position: fixed;
    top: 0;
    display: none;
    float: left;
    /*position: absolute;*/
    /*margin-top: -39px;*/
    margin-top: 66px;
    background: $color-black-2;
    width: 60px;
    height: 100%;
    z-index: 1000;
    z-index: 3;

    > a {
      margin-top: 15px;
      display: block;
      text-align: center;
      color: $color-white;
    }

    .side-nav {
      top: auto;
      background: $color-black-2;
      padding-bottom: 80px;
      height: 100%;

      li {
        line-height: 60px;

        a {
          display: flex;
          align-items: center;
          padding: 0 20px;
          line-height: inherit;
          width: 100%;
          height: 60px;
          line-height: 13px;
          font-size: rem-calc(13);
          font-weight: 300;
          color: $color-white;
          overflow: hidden;

          &:not(.close-side-nav):hover,
          &:not(.close-side-nav):active,
          &:not(.close-side-nav):focus {
            background: $color-red;
          }

          &.close-side-nav {

            i {
              position: relative;
              top: -4px;
              left: -3px;
              color: $color-white;
            }

          }

        }

      }

    }

  }

  .controls {
    position: absolute;
    top: 19px;
    left: 82px;
    z-index: 1;

    @media (max-width: $tablet-down) {
      left: 0;
    }

    a {
      font-weight: 700;
    }
  }

  .menu {
    margin: 0 0 30px 0 !important;
    background: $color-white !important;
    height: auto !important;

    .section {
      position: relative;
      margin: 0 -17px;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 14px;
      padding-right: 14px;

      &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: 1px solid $color-grey-5;
        content: "";
        width: 100%;
      }

      &.header {
        background-color: $color-grey-4;

        li {
          color: $color-black;

          span {
            color: $color-red;
          }
        }

      }

      &:last-child:after {
        display: none;
      }

      ul {

        li {
          line-height: 1.75;
          font-size: rem-calc(12);
          font-weight: 400;

          a {
            color: $color-black-2;

            &:hover,
            &:active,
            &:focus {
              color: $color-red;
            }
          }

        }

      }

      &:last-child {

        ul {
          margin-bottom: 0.75rem;
        }

      }

    }

    + div > .row {
      margin-bottom: 12px;
    }

  }

}

.drag-target {
  width: 60px;
  z-index: 1;

  @media (max-width: $tablet-down) {
    display: none;
  }
}

#sidenav-overlay {
  display: none !important;
}

/* ELEMENTS */

.material-icons {
  vertical-align: middle;
}

.loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(190, 192, 194, 0.8);
  width: 100%;
  height: 100%;
  z-index: 3;
}

.preloader-wrapper {

  &.small {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 24px);
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  &.main {
    position: fixed;
    top: 50%;
    left: calc(50% - 25px);
    z-index: 2;
  }

  .spinner-layer {
    border-color: $color-blue-2;
  }
}

.tabs {
  border-bottom: 1px solid $color-grey-5;
  margin-bottom: 14px;
  height: 38px;
  overflow: visible;

  .tab {
    padding: 0 !important;
    height: 38px;
    line-height: 38px;

    a {
      padding: 0;
      font-family: $font-secondary;
      font-size: rem-calc(18);
      font-weight: 400;
      color: $color-grey-7;
      text-align: left;

      &.active {
        color: $color-black-2;
      }
    }

  }

  .indicator {
    bottom: -1px;
    background-color: $color-red;
    height: 4px;
  }

}

.row {

  .dashboard &,
  .fsp & {
    margin-left: -17px;
    margin-right: -17px;
  }

  .admin:not(.fsp-details) & {
    margin-left: -17px;
    margin-right: -17px;
  }

  .col {

    .dashboard &,
    .fsp & {
      padding: 0 17px;
    }

    .admin:not(.fsp-details) & {
      padding: 0 17px;

      /*+ .col {
          padding: 0;
      }*/

      .row {
        margin-left: 0;
        margin-right: 0;

        .col {
          padding: 0;
        }

      }

    }

  }

  .card {
    border-radius: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.10), 0 1px 5px 0 rgba(0, 0, 0, 0.10), 0 3px 1px -2px rgba(0, 0, 0, 0.10);
    background: $color-grey-1;

    &.authentication {
      /*position: absolute;
      top: 50%;
      transform: translateY(-50%);*/
      margin: 0 auto 0 auto;
      box-shadow: 0 2px 2px 0 rgba(36, 37, 37, 0.10), 0 1px 5px 0 rgba(36, 37, 37, 0.10), 0 3px 1px -2px rgba(36, 37, 37, 0.10);
      background: $color-white;
      max-width: 600px;

      .card-content {
        padding: 42px 60px 27px 60px;
        border-radius: 0;
      }
    }

    .dashboard & {
      margin: 17px 0;
      height: 200px;

      .card-content {
        padding: 0;
        height: 100%;

        a {
          display: block;
          height: 100%;
          padding: 20px;
          color: $color-black-2;
          text-transform: uppercase;
          text-align: center;

          &:hover {
            display: flex;
            align-items: center;
            background: $color-red;
            color: $color-white;

            .icon-container {
              display: none;
            }

            span {
              position: static;
            }
          }

          span {
            position: absolute;
            left: 0;
            bottom: 15px;
            text-align: center;
            width: 100%;
          }
        }
      }

      .icon-container {
        display: block;
        margin: 0 auto 41px auto;
        border-radius: 50%;
        background: $color-grey-4;
        width: 106px;
        height: 106px;
        line-height: 109px;
        font-size: 60px;
        color: $color-white;

        /*&.icon-fsp_clients {
            background-image: url('/static/img/icon-fsp_clients.png');
        }

        &.icon-generate_reports {
            background-image: url('/static/img/icon-generate_reports.png');
        }

        &.icon-list_management {
            background-image: url('/static/img/icon-list_management.png');
        }

        &.icon-document_templates {
            background-image: url('/static/img/icon-document_templates.png');
        }

        &.icon-email_templates {
            background-image: url('/static/img/icon-email_templates.png');
        }

        &.icon-fsb_email_templates {
            background-image: url('/static/img/icon-fsb_email_templates.png');
        }

        &.icon-followup_email_templates {
            background-image: url('/static/img/icon-followup_email_templates.png');
        }*/

      }

    }

  }
}

table:not(.picker__table) {
  table-layout: fixed;
  overflow-x: scroll;
  margin-bottom: 30px;

  caption {
    border-radius: 0;
    padding: 20px 20px 18px 20px !important;
    line-height: 1.1;
    font-family: $font-secondary;
    font-weight: 400;
    font-size: rem-calc(13);
    text-align: left;
    vertical-align: middle;
    text-transform: uppercase;

    &.bg-red {
      color: $color-white;
    }
  }

  thead {
    border-bottom: 0;

    tr {
      border: 0;
      background: $color-grey-4;

      th {
        float: none !important;
        border-radius: 0;
        padding: 16px 18px 14px 16px !important;
        line-height: 1.1;
        font-family: $font-secondary;
        font-weight: 400;
        font-size: rem-calc(13);
        vertical-align: middle;
        text-transform: uppercase;
        /*white-space: nowrap;*/

        b {
          font-weight: 500;
        }
      }

      &.spacer {
        background: transparent;

        th {
          padding: 0 !important;
          height: 18px;
          line-height: 18px;
          font-size: rem-calc(13);
        }
      }
    }

  }

  tbody {
    background: $color-white;
    padding: 3px 0 3px;

    tr {

      &.pointer {
        cursor: pointer;
      }

      td {
        float: none !important;
        border-radius: 0;
        padding: 20px 20px 18px 20px !important;
        line-height: 1.1;
        font-size: rem-calc(13);
        word-break: normal;

        @media (max-width: $tablet-down) {
          word-break: normal;
        }

        b {
          font-weight: 500;
        }
      }
    }

  }

  .dashboard:not(.bg-grey-1) & {

    &:not(.vertical) {

      tbody {
        background: $color-grey-1;
      }

    }

  }

  &.bordered {

    tbody {

      tr {
        border-bottom: 1px solid $color-grey-5;
      }

    }

  }

  &.striped > tbody > tr {

    &:nth-child(odd) {
      background-color: $color-white;
    }

    &:nth-child(even) {
      background-color: $color-grey-1;
    }

  }

  &.border {
    border: 1px solid $color-grey-4;
  }

  .fsp.fsp-details & {

    tr:not(.spacer) {

      td {
        font-size: rem-calc(12);
      }

      th {
        padding: 12px 20px 12px 20px !important;
        font-size: rem-calc(12);
        /*white-space: normal;*/
      }

    }

  }

  .report-generate & {

    th {
      padding: 13px 20px 13px 20px !important;
      font-size: rem-calc(12);
    }

    td {
      padding: 18px 20px 19px 20px !important;
      font-size: rem-calc(12);
    }

    tr.sub-header {
      background-color: #EBEBEB;
      font-weight: bold;
    }

  }

  .report-risk & {

    th.main {
      padding: 5px !important;
      font-size: rem-calc(12);
      border: 1px solid #DDDDDD !important;
      background-color: $color-red !important;
      color: #FAFAFA;
    }

    td {
      vertical-align: middle;
      padding: 8px 5px !important;
      font-size: rem-calc(12);
      border: 1px solid #DDDDDD !important;

      input[type=text] {
        margin: 0;
      }

      .high-risk {
        font-weight: bold !important;
        color: $color-red !important;
      }

      .med-risk {
        font-weight: bold !important;
        color: #ffa31f !important;
      }

      .low-risk {
        font-weight: bold !important;
        color: #53b660 !important;
      }

      .prob-impact {
        text-transform: uppercase;
        text-align: center;
      }

    }

    tr.sub-header {
      background-color: #EBEBEB;
      font-weight: bold;
    }

  }

  .report-preview & {

    caption {
      padding: 11px 9px 10px 9px !important;
      font-weight: 500;
    }

    th {
      padding: 11px 9px 10px 9px !important;
      text-transform: none;
      font-size: rem-calc(11);
      font-weight: 500;
    }

    td {
      padding: 11px 9px 10px 9px !important;
      font-size: rem-calc(11);
      vertical-align: top;
    }

  }

  &.vertical {

    > tbody {

      > tr {
        background: $color-grey-4;

        > td {
          display: inline-block;
          background: $color-white;

          input,
          textarea,
          select,
          .file-field .btn,
          .file-field .btn:hover {
            margin-bottom: 0;
          }

        }

        > th {
          float: none !important;
          display: inline-block;
          border-radius: 0;
          padding: 20px 20px 18px 20px !important;
          line-height: 1.1;
          font-size: rem-calc(13);
          font-family: $font-secondary;
          font-weight: 400;
          vertical-align: middle;
          text-transform: uppercase;

          b {
            font-weight: 500;
          }
        }

      }

    }

  }

}

div.report-footer {

  padding-top: 40px;
  font-size: 12px;
  color: #434343;

  p.review {

    padding: 30px 5px;

    span.review-description {
      font-style: italic;
      font-size: 12px;
    }
  }

  p.signature {
    padding: 10px 5px;

  }

  p.signature.first {
    padding-top: 40px;
  }

  li {
    padding: 5px;
  }

}

.btn {
  border-radius: 4px;
  background-color: $color-red;
  padding: 0 2.25rem;
  letter-spacing: -.2px;
  color: $color-white;
  transition: all .25s;

  &.width-fixed {
    padding: 0;
    width: 105px;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $color-blue-2;
    color: $color-white;
  }

  &.bg-grey-5 {
    background-color: $color-grey-5;

    &:hover,
    &:active,
    &:focus {
      background-color: $color-blue-2;
    }
  }

  &.btn-small {
    padding: 0 0.75rem;
  }

  + .btn {
    margin-left: 10px;
  }

}

tr.not-applicable {
  background-color: #EBEBEB;
  color: #444444;

  td {
    background-color: #EBEBEB;
    color: #444444;
  }
}

.btn-floating {
  position: fixed;
  top: 100px;
  margin-left: -25px;
  background-color: $color-red;
  width: 70px;
  height: 70px;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-blue-2;
    color: $color-white;
  }

  .fsp & {
    right: 37px;
  }

  i {
    line-height: 70px;
  }
}

.file-field {

  .btn, .btn:hover {
    display: block;
    float: none;
    margin: 0 auto 15px auto;
    border: 1px solid $color-grey-5;
    background: none;
    box-shadow: none;
    padding: 38px 10px 0 7px;
    width: 100%;
    height: 160px;
    max-width: 500px;
    text-align: center;

    span.dropdown-hint {
      display: block;
      margin-top: 5%;
      font-size: rem-calc(12);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: none;
      color: $color-black-2;
    }
  }

  .file-path-wrapper {

    .file-path {
      position: absolute;
      margin: 0 0 -1px 0;
      padding: 0;
      width: 1px;
      height: 1px;
      visibility: hidden
    }

  }

  .logo {
    display: block;
    margin: 0 auto 15px auto;
    max-width: 300px;
  }

}

.select-wrapper {

  .select-dropdown {

    &[type="text"] {

      &:focus {
        border-bottom: 1px solid $color-grey-5 !important;
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
      }

    }

    li {
      min-height: 40px;

      &.disabled, &.disabled > span {
        color: $color-grey-7;
        background-color: transparent;
      }

      &.selected {
        background-color: $color-grey-1;
      }

      &:hover {
        background-color: $color-grey-4;
      }

      span {
        padding: 9px 10px;
        font-size: rem-calc(14);
        color: $color-red;
      }

      > span > label {
        top: 0;
        height: 12px;
      }

    }

  }

  span.caret {
    right: 9px;
  }

}

.modal {
  /*top: 25% !important;*/

  h2 {
    text-align: left;
    color: $color-red;
    text-transform: uppercase;
  }

  p {

    &:last-child {
      margin-bottom: 0;
    }

  }

  .modal-footer {
    border-top: 1px solid $color-grey-5;
    padding: 0 20px;
    line-height: 56px;

    a {
      font-weight: 700;

      &:last-child {
        margin-left: 22px;
      }

      i {
        font-weight: 700;
      }
    }

  }

}

.modal-overlay {
  background: $color-grey-2;
}

.list-dropdown {
  position: relative;
  display: inline-block;
}

.list-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 320px;
  max-width: 500px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 8px 8px 0px 8px;
  z-index: 9999;
}

.list-dropdown:hover .list-dropdown-content {
  display: block;
}

.picker__date-display {
  background-color: $color-red;
}

.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  background-color: $color-red;
}

.picker__footer {

  button {
    color: $color-red;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: $color-blue-2;
    }
  }

}

.checkbox {
  padding-left: 0 !important;
  height: 15px !important;
  line-height: 15px !important;
}

[type="checkbox"] {

  &.filled-in {

    + label {
      padding-left: 0 !important;
      height: 15px !important;
      line-height: 15px !important;
    }

    &:not(:checked) + label {

      &:after {
        border: 2px solid $color-grey-5;
      }

    }

    &:checked + label {

      &:after {
        border: 2px solid $color-red;
        background-color: $color-red
      }

    }

  }

  &:not(.filled-in) {

    &:not(:checked) {

      &:disabled + label {

        &:before {
          background-color: $color-grey-5;
        }

      }

      + label:before {
        border: 2px solid $color-grey-5;
      }

      + label:after {
        border: 2px solid $color-grey-5;
      }
    }

    &:checked {

      + label:before {
        top: 0;
        left: 0;
        border-right: 2px solid $color-red;
        border-bottom: 2px solid $color-red;
        width: 8px;
        height: 13px;
      }

    }

  }

  div > &,
  table.vertical & {

    &.filled-in {

      + label {
        padding-left: 35px !important;
        margin-bottom: 20px;
        color: $color-black;
      }

      &:not(:checked) + label {

        &:after {
          top: -3px;
        }

      }

      &:checked + label {

        &:before {
          top: -3px;
        }

        &:after {
          top: -3px;
        }

      }

    }

  }

  table.vertical & {

    &.filled-in {

      + label {
        margin: 0;

        + div {
          margin-top: 20px;
        }
      }

    }

  }

}

/* CUSTOM */

.search {
  position: relative;
}

form {

  &.col {
    float: none !important;
    margin: 0 auto;
  }

  .fsp > .row > .col & {

    &.l8 {
      width: 51.65%
    }
  }

  p {

    &.link {
      margin-top: -11px !important;
      margin-bottom: 7px !important;
      text-align: inherit;
    }

  }

  textarea + p {
    margin-top: 0;
  }

}

div:not(.buttons) {

  &.border-bottom:not(:last-child) {
    border-bottom: 1px solid $color-grey-5;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    overflow: hidden;
  }

}

.buttons {

  &.border-top {
    border-top: 1px solid $color-grey-5;
    padding-top: 2rem;
    width: 100%;
    text-align: right;
  }

}
